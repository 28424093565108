import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

function Privacy() {
  const tdborder = { border: "1px solid #999", padding: "10px" }

  useEffect(() => {
    window.location = "https://www.inspectionsupport.com/privacy/";
  }, [])

  return (
    <div>
      <Helmet
        title="ISN CCPA Privacy Notice - Inspection Support Network"
        defer={false}
      />
      <Container>
        <Header />
      </Container>
      <Container className={'legacy-page-spacer'} fluid>
        <Row style={{ paddingTop: "100px" }}>
          <Col sm={12}>
          Inspection Support LLC Privacy Notice for California Residents
          </Col>
          <Col sm={12}>
            <br/>
            <p>
              <strong>Last Updated:</strong> April 5, 2021
            </p>
            <h1>
            The California Consumer Privacy Act of 2018, as amended (“CCPA”) requires specific additional privacy disclosures to be provided to California consumers (“consumers” or “you”).  This Privacy Notice for California Residents (“CCPA Notice”) of Inspection Support Limited Liability Company (“ISN”) supplements ISN’s general privacy policy (“General Privacy Policy”).  
            </h1>
            <p>
            All terms in this CCPA Notice are meant to be defined and used as those same terms are defined and used in the CCPA, even if a different definition is used in the General Privacy Policy.  In the event of a conflict between the terms of this CCPA Notice and the General Privacy Policy, this CCPA Notice controls as to California consumers’ personal information.
            </p>
            <p>
              <strong>Personal Information We Collect</strong>
            </p>
            <p>ISN has collected the following categories of consumer personal information within the last 12 months: </p>
            <table>
              <tbody>
                <tr>
                  <td style={tdborder}>
                    <p>
                      <strong>Category</strong>
                    </p>
                  </td>
                  <td style={tdborder}>
                    <p>
                      <strong>Examples</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>A. Identifiers.</p>
                  </td>
                  <td style={tdborder}>
                    <p>
                    A real name, postal address, unique personal identifier, Internet Protocol address, email address, phone number, date of birth, driver's license number, or other similar identifiers.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>
                      B. Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code &sect;
                      1798.80(e)).
                    </p>
                  </td>
                  <td style={tdborder}>
                    <p>
                    A name, address, telephone number, driver's license or state identification card number, insurance policy number, education, employment, employment history, credit card number, debit card number, or any other financial information. 
                    </p>
                    <p>
                    Some personal information included in this category may overlap with other categories.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>C. Protected classification characteristics under California or federal law.</td>
                  <td style={tdborder}>Age (40 years or older), marital status, sex (including gender, gender identity, gender expression,), or veteran or military status.</td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>D. Commercial information.</p>
                  </td>
                  <td style={tdborder}>
                    <p>
                    Records of personal and real property, products or services purchased, rented, obtained, or considered, or other purchasing or consuming histories or tendencies.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>F. Internet or other similar network activity.</p>
                  </td>
                  <td style={tdborder}>
                    <p>
                    Search history, information on a consumer's interaction with a website, application, or advertisement while using or interacting with ISN’s websites and applications.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>G. Geolocation data.</p>
                  </td>
                  <td style={tdborder}>
                    <p>Physical location or movements. </p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>I. Professional or employment-related information.</p>
                  </td>
                  <td style={tdborder}>
                    <p>Current or past job history or performance ratings.</p>
                  </td>
                </tr>
                <tr>
                  <td style={tdborder}>
                    <p>K. Inferences drawn from other personal information.</p>
                  </td>
                  <td style={tdborder}>
                    <p>
                      Profile reflecting a person's preferences, characteristics, predispositions, and behavior.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <p>ISN obtains the categories of personal information listed above from the following categories of sources:</p>
            <ul>
              <li>Directly from you. For example, from forms you complete or products and services you request or purchase.</li>
              <li>Indirectly from you. For example, from observing your actions on our Website and through cookies and other similar technology.</li>
              <li>From other businesses that ISN works with to provide consumers services, including our corporate affiliate Porch.com, Inc. (“Porch”) and other companies in the Porch family of companies, and our and their business partners.</li>
              <li>From social networks. </li>
              <li>From sources generally available to the public, such as websites, and consumer data collection companies.</li>
            </ul>
            <p>
              <strong>Use of Personal Information</strong>
            </p>
            <p>We may use, disclose, or share the personal information we collect from California residents as described below. </p>
            <p>We may use, disclose, or share personal information for one or more of the following business purposes: </p>
              <ul>
                <li>
                To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our services, we will use that personal information to respond to your inquiry and, in the case of service requests, to connect you with service providers and other companies that receive or help us meet service requests. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate fulfillment. We may also save your information to facilitate new service requests or purchases. 
                </li>
                <li>
                To provide, improve, support, personalize, and develop our website and services and those of the Porch family of companies.
                </li>
                <li>To create, maintain, customize, and secure your account with us.</li>
                <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
                <li>To provide you with support, to respond to your inquiries, and to otherwise communicate with you and manage the customer relationships, including to investigate and address your concerns and monitor and improve our responses.</li>
                <li>To deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our website and those of Porch family of companies and third parties, and via email or text messages (with your consent, where required by law).</li>
                <li>To optimize and analyze the effectiveness of advertising and marketing campaigns.</li>
                <li>To administer contests, promotions or sweepstakes.</li>
                <li>To run and help maintain the safety, security, and integrity of our website and services, databases and other technology assets (including services, databases and assets shared with Porch family of companies), and business.</li>
                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                <li>To detect, investigate and prevent fraud and abuse and activities that may be illegal or violate our policies, and to otherwise protect our users, other third parties and our business.</li>
                <li>To create de-identified (anonymous) information for our own uses and use by the Porch family of companies and our and their business partners.</li>
                <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of ISN's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by ISN about our users is among the assets transferred.</li>
                <li>As otherwise described to you when collecting your personal information.</li>
              </ul>
              <p>As set forth in the General Privacy Policy, ISN uses cookies and other tracking technology and allows others to use such technology on our sites.&nbsp; Please see the General Privacy Policy for more information on the use of cookies and other tracking technology.&nbsp;</p>
              <p>ISN will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
              <p>
              <strong>Sharing Personal Information</strong>
            </p>
            <p>
            <u>Porch.</u> We share personal information with Porch. Porch uses personal information in accordance with its privacy policy located at <a href="https://porch.com/about/privacy">https://porch.com/about/privacy</a>, including for improving its products and services, marketing, and disclosures to the Porch family of companies and other third parties. Porch will be bound by the terms of this CCPA Notice with respect to personal information of California residents received from ISN and will use that information as described in this CCPA Notice.
            </p>
            <p>
            <u>Companies Fulfilling a Business Purpose.</u> We disclose your personal information to companies providing services to us, other businesses, or other third parties for a business purpose. 
            </p>
            <p>
            <u>Advertising.</u> As noted above, we allow cookies and pixels to share activity data to serve more relevant ads. 
            </p>
            <p>
            <u>Consumer-Directed Disclosures.</u> We will share your personal information with businesses and third parties in connection with something you have directed us or them to do. For example, when you ask ISN to connect you to a company with which we partner to offer you services, we will provide your information to that ISN partner.  
            </p>
            <p>
            <u>Compliance with Laws; Protection of Rights.</u> To the extent allowed under the CCPA, we may release your personal information when we believe that doing so is appropriate to comply with applicable laws, regulations, subpoenas, warrants or court orders, or other legal requirements; to cooperate with government or law enforcement; to resolve complaints or disputes; to avoid legal liability; to comply with, enforce or investigate potential violations of our agreements, our Terms of Service, program-specific terms, the General Privacy Policy as supplemented by this CCPA Notice, or other policies; to bill and collect amounts owed to us; to protect our rights, reputation, safety or property or that of others; and to address fraud, security or technical issues.
            </p>
            <p>
            <u>Sale/Merger.</u> We may decide to sell, buy, merge or reorganize our own or other businesses, conduct a securities offering, or do a joint venture or other strategic transaction. We could also be involved in a bankruptcy, liquidation, dissolution or similar transaction. Any such transaction may involve disclosing personal information. We would seek protections of confidentiality and restricted use for all information disclosed. In the event that we or all of our assets are acquired in such a transaction, our collected information would be one of the transferred assets.
            </p>
            <p>
            <u>Contest/Sweepstakes.</u> We may publicly disclose the name, city and state of winners.
Except as described in this CCPA Notice, we will not sell or otherwise provide to third parties any personal information provided to us (including personal information included in an inspection report).
            </p>
            <p>
              <strong>Disclosures of Personal Information for a Business Purpose</strong>
              <p>In the last 12 months, ISN has disclosed the following categories of personal information for a business purpose to the following categories of third parties: </p>
            </p>
            <p>&nbsp;</p>
              <table>
              <tbody>
              <tr>
              <td style={tdborder}>
              <p><strong>Personal Information Category</strong></p>
              </td>
              <td style={tdborder}>
              <p><strong>Third Party Categories</strong></p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category A: Identifiers</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category B: California Customer Records personal information categories</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category C: Protected classification characteristics under California or federal law</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category D: Commercial information</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category F: Internet or other similar network activity</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              <p>Advertising networks</p>
              <p>Data analytics providers</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category G: Geolocation data</p>
              </td>
              <td style={tdborder}>
              <p>None</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category I: Professional or employment-related information</p>
              </td>
              <td style={tdborder}>
              <p>Companies providing services to ISN</p>
              <p>Third parties upon a request by the consumer that requires disclosure</p>
              <p>Porch</p>
              </td>
              </tr>
              <tr>
              <td style={tdborder}>
              <p>Category K: Inferences drawn from other personal information</p>
              </td>
              <td style={tdborder}>
              <p>Porch</p>
              </td>
              </tr>
              </tbody>
              </table>
              <br/>
              <p>
              <strong>Sales of Personal Information </strong>
            </p>
            <p> In the 12 months preceding the posted date of this CCPA Notice, we have not sold personal information (as defined in the CCPA), except as provided below. </p>
            <p><strong>As noted above, your personal information may be shared with advertisers that help provide our ads to you. We do not sell this information for monetary consideration but, depending on the circumstances, such sharing may be considered to be a sale under the CCPA.  </strong></p>
            <p>
              <strong>Your Rights and Choices </strong>
            </p>
            <p>This section describes consumer CCPA rights and explains how to exercise those rights.</p>
            <p>
              <strong>Access to Your Personal Information</strong>
            </p>
            <p>You have the right to request that ISN disclose certain information to you about our collection and use of your personal information over the past 12 months, including a description of your personal information collected, used, disclosed and sold by ISN.</p>
            <p>
              <strong>Deletion Request Rights </strong>
            </p>
            <p>You have the right to request that ISN delete any of your personal information that we collected from you and retained, subject to certain exceptions.  </p>
            <p>
              <strong>Exercising Access and Deletion Rights</strong>
            </p>
            <p>To exercise the access and deletion rights described above, please submit a verifiable consumer request to us by either: </p>
            <ul>
              <li>Calling us toll free at (800) 700-8112.</li>
              <li>Visiting our webpage at <a href="https://www.inspectionsupport.net/ccpa-request/">www.inspectionsupport.net/ccpa-request/</a>. </li>
            </ul>
            <p>Only you, or someone that you authorize to act on your behalf as described below, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. </p>
            <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you, or your request does not contain sufficient detail to allow us to understand, evaluate, and respond to it. Please provide your full name, mailing address and email address in any request to exercise your rights under the CCPA; we will contact you if we need additional information to verify your request. </p>
            <p>We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request. </p>
            <p>We will work to respond to your request within 45 days of receipt. We will not charge you a fee for making a request unless it is excessive, repetitive, or manifestly unfounded. If we determine that your request warrants a fee, we will notify you of the fee and explain that decision before completing your request.</p>
            <p>We may be unable to process your request for access or deletion because of a conflict with law or an exception under the CCPA.  In those cases, unless prohibited by law, we will tell you the reason why we have rejected your request.</p>
            <p>A California resident may use an authorized agent to submit a request. To make a request on behalf of a consumer, the authorized agent must provide us with a copy of the consumer’s written and signed authorization of the agent. We may also require the consumer to verify their own identity. These requirements will not apply where the agent provides us with a copy of a power of attorney from the consumer that complies with Cal. Prob. Code Sections 4000 to 4465.</p> 
            <p>
              <strong>Opting out of Sales of Personal Data</strong>
            </p>
            <p>You have the right to opt out of the sale (as defined in the CCPA) of your personal information. To opt out, click the following link: <a href="https://www.inspectionsupport.net/ccpa-do-not-sell">Do Not Sell My Personal Information</a></p>
            <p>If you submit an opt-out request, we will not ask you to reauthorize the sale of your personal information for at least 12 months. You may change your mind and opt back into sales of your personal data at any time by contacting us at help@inspectionsupport.com.</p>
            <p>If you opt out of the sale of your personal information, you will not be opted out from transactions that the CCPA does not deem to be sales.</p>
            <p>
              <strong>Non-Discrimination</strong>
            </p>
              <p>We will not discriminate against you for exercising any of your CCPA rights. </p>
            <p>
              <strong>Contacting Us</strong>
            </p>
            <p>If you have any questions about this CCPA Notice, please contact us by email at help@inspectionsupport.com or in writing at Inspection Support LLC, Attention: ISN Legal Department, 2200 1st Ave South, Seattle, WA 98134.</p>
            <p>
              <strong>Changes to CCPA Notice</strong>
            </p>
            <p>ISN reserves the right to amend this CCPA Notice at our discretion and at any time. When we make changes to this CCPA Notice, we will post the updated CCPA Notice on the website and update its effective date. <strong>Your continued use of the Services (as defined in the General Privacy Policy) following the posting of changes constitutes your acceptance of such changes.</strong></p>
            <p>
              <strong>Changes to CCPA Notice</strong>
            </p>
            <p>ISN does not knowingly collect personal information of consumers under 16 years of age.</p>
            </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default Privacy
